<template>
  <section class="w-full">

    <div class="grid place-content-center m-4">
      <div class="boxed-tabs nav nav-tabs inline-flex rounded-md shadow-sm border bg-white border-gray-200" role="group">
        <router-link
          v-for="(item, index) in items"  :key="index"
          :id="item.id"
          :to="item.to"
          :class="item.class"
          class="butonMenu text-sm btn-des-right grid place-content-center inline-flex items-center py-2 px-4 text-sm font-medium text-blue-800 bg-white rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:rounded-r-lg focus:z-10 focus:ring-blue-700 focus:text-white focus:bg-blue-800 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          {{ item.label }}
        </router-link>
      </div>
    </div>
    <router-view class="w-100" />

<!--    <CardAuditoria />
    <GraficaAuditoria />
    &lt;!&ndash; <CardNotaTecnica /> &ndash;&gt;
    <CardNotaTecnicaInfo />-->
  </section>
</template>

<script>

import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    // CardAuditoria: defineAsyncComponent(() => import('./components/cardAuditoria')),
    // GraficaAuditoria: defineAsyncComponent(() => import('./components/graficaAuditoria')),
    // CardNotaTecnica: defineAsyncComponent(() => import('./components/cardNotaTecnica')),
    // CardNotaTecnicaInfo: defineAsyncComponent(() => import('./components/cardNotaTecnicaInfo'))
  },
  setup () {
    const route = useRoute()
    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null
    })

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    const items = computed(() => {
      return [
        {
          id: 'ut-especializada',
          to: { name: 'ut-especializada.operacion.ut-especializada', params: { type: 1 } },
          class: getActive([
            'ut-especializada.operacion.ut-especializada'
          ]),
          label: 'UT Especializada'
        },
        {
          id: 'ssd-norte-santander',
          to: { name: 'ut-especializada.operacion.ssd-norte-santander', params: { type: 2 } },
          class: getActive([
            'ut-especializada.operacion.ssd-norte-santander'
          ]),
          label: 'SSD Norte Santander'
        },
        {
          id: 'ssd-santander',
          to: { name: 'ut-especializada.operacion.ssd-santander', params: { type: 3 } },
          class: getActive([
            'ut-especializada.operacion.ssd-santander'
          ]),
          label: 'SSD Santander'
        }
      ]
    })

    return {
      filters,
      items,
      getActive
    }
  }
}
</script>
